import React from 'react';
import {
  Seo, PageHeader, DeveloperPost, Footer,
} from 'components/common';
import { siteUrl } from 'configs/constants';

function PagePage() {
  return (
    <>
      <Seo title="Developer Center" url={`${siteUrl}/developers/`} />
      <PageHeader />
      <DeveloperPost />
      <Footer />
    </>
  );
}

export default PagePage;
